import { Component, OnInit } from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { RegistrationFormComponent } from '../registration-form/registration-form.component';
import { IContactModel } from '../models/contact.model';
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  twitterUrl = 'https://twitter.com/@mc3antilles';
  likedinUrl = 'https://www.linkedin.com/company/mc3-ocean-indien';
  isExpanded = false;
  openRegistrarion = false;
  constructor(private modalService: NgbModal)  {

  }

  
  openJoinForm() {
    const modalRef = this.modalService.open(RegistrationFormComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'World';
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
