import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';
import { AccountRegistrationModel } from '../models/account-registration.model';

@Injectable()
export class AccountService {

  constructor(private srv: CoreService) { }

  public registerAccount(model: AccountRegistrationModel): Observable<any> {
    return this.srv.post(`${this.srv.baseUrl}/account/RegisterAccount/`, model);
  }
}
