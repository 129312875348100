import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreService } from './services/core.service';
import { AccountService } from './services/account.service';
import { ClarityModule } from '@clr/angular';
import { OnlyNumber } from './directives/onlynumbers.directive';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    RegistrationFormComponent,
    OnlyNumber,
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    NgbModule,
    BrowserAnimationsModule,
    ClarityModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'register', component: RegistrationFormComponent },
      { path: '**', redirectTo: '' }
    ])
  ],
  entryComponents: [
    RegistrationFormComponent
  ],
  providers: [NgbActiveModal, CoreService, AccountService],
  bootstrap: [AppComponent]
})
export class AppModule { }
