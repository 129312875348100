import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CoreService {
  constructor(private http: HttpClient) {

  }
  public baseUrl = 'http://valhalla-dev.devblockcloud.com/api.core/api';
  public identityUrl = 'http://valhalla-dev.devblockcloud.com/api.identity/api';

  private createAuthorizationHeader() {
    const token = localStorage.getItem('jwt');
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + token);
    headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token, content-type');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT');
    headers.append('Access-Control-Allow-Origin', '*'); // TODO: this needs to be configure
    return headers;
  }

  private formatErrors(error: any) {
    console.log(error.error);
    return throwError(error.error);
  }

  public get(url: string): Observable<any> {
    const headers = this.createAuthorizationHeader();
    return this.http.get<any>(url, { headers: headers })
      .pipe(catchError(this.formatErrors));
  }

  public post(url: string, obj: any): Observable<any> {
    const headers = this.createAuthorizationHeader();
    return this.http.post<any>(url, obj, { headers: headers })
      .pipe(catchError(this.formatErrors));
  }
  public postProgress(url: string, obj: any): Observable<any> {
    const headers = this.createAuthorizationHeader();
    return this.http.post(url, obj, { headers: headers, reportProgress: true })
      .pipe(catchError(this.formatErrors));
  }
}
