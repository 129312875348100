import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountRegistrationModel } from '../models/account-registration.model';
import { NgForm } from '@angular/forms';
import { AccountService } from '../services/account.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.css']
})

export class RegistrationFormComponent implements OnInit {
  model: AccountRegistrationModel;
  @Input() name;
  step: number;

  constructor(public activeModal: NgbActiveModal, private srv: AccountService) { }

  ngOnInit() {
    this.model = AccountRegistrationModel.createNew();
    this.step = 1;
  }


  onRegister(form: NgForm) {
    console.log(form);
    if (form.valid) {
      this.srv.registerAccount(this.model).subscribe(data => {
        console.log(data);
        if (!data.result) {
          swal('message', data.errorMessage, 'error');
          this.step === 1;
        } else {
          swal('message', 'Successfully Registered', 'success');
          this.activeModal.close();
        }
      }, err => this.onError(err));
    }
  }

  onError(msg) {
    this.step === 1;
    swal({
      position: 'top-end',
      type: 'error',
      title: 'Oops...',
      text: msg,
      footer: '<a href>Why do I have this issue?</a>'
    });
  }

}
