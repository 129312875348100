import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { IContactModel } from '../models/contact.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  team: Array<any> = [];
  clients: Array<any> = [];
  vendors: Array<any> = [];

  contactUsModel: IContactModel;
  ngOnInit(): void {
    this.getTeamMembers();
    this.getClients();
    this.getVendor();
    this.resetContactForm();
  }

  private resetContactForm() {
    this.contactUsModel = <IContactModel>{
      fullName: '',
      domain: '',
      category: '',
      email: '',
      msg: ''
    };
  }
  sendContactForm(form: NgForm) {
    console.log(this.contactUsModel);
    this.resetContactForm();
    form.resetForm(this.contactUsModel);

  }

  getTeamMembers() {
    this.team = [];
    this.team.push({ name: 'Walter BERREHOUC', title: 'Directeur d’agence OI' });
    this.team.push({ name: 'Sébastien MOUTON', title: 'Responsable Retail Réunion' });
    this.team.push({ name: 'Vincent ROULLAND', title: 'Responsable d’agence Mayotte' });
    this.team.push({ name: 'Aldo QUADRI', title: 'directeur  Polynésie' });
    this.team.push({ name: 'Medhi HASSOUNI', title: 'Directeur d’agence Nouvelle Calédonie' });
    // this.team.push({ name: 'Hervé RABANT', title: 'Directeur d’Agence Guadeloupe' });
    // this.team.push({ name: 'Boris CHODKIEWIEZ', title: 'Chef Produit Licences et Projet ESD MC3' });
  }

  getClients() {
    this.clients = [];
    this.clients.push({ name: 'BV', imageUrl: '/assets/images/clients/BV.png' });
    this.clients.push({ name: 'Microforce', imageUrl: '/assets/images/clients/microforce.png' });
    this.clients.push({ name: 'PC Leader', imageUrl: '/assets/images/clients/Pc_Leader.png' });
    this.clients.push({ name: 'Digilife', imageUrl: '/assets/images/clients/digilife.png' });
    this.clients.push({ name: 'Librairie Antillaise', imageUrl: '/assets/images/clients/librairie-antillaise.png' });
    this.clients.push({ name: 'Altobog', imageUrl: '/assets/images/clients/AltobogFlatRVB.png' });
 

  }

  getVendor() {
    this.vendors = [];
    this.vendors.push({ name: 'Bitdefender', imageUrl: 'assets/images/bitdefender.png', class: 'col-md-3 px-0' });
    this.vendors.push({ name: 'Microsoft', imageUrl: 'assets/images/microsoft.png', class: 'col-md-6 px-0' });
    this.vendors.push({ name: 'EBP', imageUrl: 'assets/images/ebp.png', class: 'col-md-3 px-0' });
  }


}
